// 应急预案添加
<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="780"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>预案名称：</p>
          <Input v-model="resplanName"
                 maxlength="30"
                 v-stringLimit
                 placeholder="计划名称"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>应急类型：</p>
          <Select v-model="emergenttypeid">
            <Option v-for="item in emergentTypeArr"
                    :value="item.id"
                    :key="item.id">{{ item.name }}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>预案等级：</p>
          <Select v-model="emergentlevelid">
            <Option v-for="item in emergentLevelArr"
                    :value="item.id"
                    :key="item.id">{{ item.name }}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>响应时长：</p>
          <Input v-model="respondtime"
                 placeholder="响应时长"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>结束时间：</p>
          <Input v-model="dealtime"
                 placeholder="处理时长"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>

      <div class="portion">
        <p class="li-title"><span class="li-musts">*</span>作业车辆要求</p>
        <Button shape="circle"
                @click.stop="onClickAddCar">
          <Icon custom="i-icon icon-jiahao"
                size="16" />
          新增车型
        </Button>
      </div>
      <ul class="form-ul"
          v-for="(item,index) in carList"
          :key="index">
        <li class="form-ul-li half">
          <p class="li-title">车辆类型：</p>
          <Select v-model="item.classid"
                  @on-change="onChangeCarType"
                  :label-in-value="true">
            <Option v-for="item in carTypeArr"
                    :value="item.id"
                    :key="item.id">{{ item.name }}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <span v-if="index==0"
                style="width:28px;"></span>
          <span v-if="index > 0"
                class="delete"
                @click.stop="onClickCarDelete(index)">删除</span>
        </li>
      </ul>

      <div class="portion">
        <p class="li-title"><span class="li-musts">*</span>作业人员要求</p>
        <Button shape="circle"
                @click.stop="onClickAddPerson">
          <Icon custom="i-icon icon-jiahao"
                size="16" />
          新增工种
        </Button>
      </div>
      <ul class="form-ul"
          v-for="(item,index) in personList"
          :key="index + 'person'">
        <li class="form-ul-li half">
          <p class="li-title">工种：</p>
          <Select v-model="item.classid"
                  @on-change="onChangePersonType"
                  :label-in-value="true">
            <Option v-for="item in personTypeArr"
                    :value="item.id"
                    :key="item.id">{{ item.name }}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <span v-if="index > 0"
                class="delete"
                @click.stop="onClickPersonDelete(index)">删除</span>
        </li>
      </ul>

    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EmergencyDispatchIntendModal',
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '应急预案'
    },
    visible: Boolean,
    dataId: String
  },
  data () {
    return {
      resplanName: '',
      emergenttypeid: '',
      emergentlevelid: '',
      respondtime: '',
      dealtime: '',
      remark: '',
      carList: [
        {
          classid: '',
          classname: '',
        }
      ],
      personList: [
        {
          classid: '',
          classname: ''
        }
      ],
      noticeArr: []
    }
  },
  computed: {
    ...mapGetters({
      'carTypeArr': 'getCarTypeAllList',
      'personTypeArr': 'getWorkTypeAllList',
    }),
    emergentLevelArr () {
      return this.$store.getters.getEmergencyLevelSelectList
    },
    emergentTypeArr () {
      return this.$store.getters.getEmergencyTypeSelectList
    },
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateCarTypeAllList',
      'updateWorkTypeAllList'
    ]),
    init () {
      this.updateCarTypeAllList()
      this.updateWorkTypeAllList()
    },
    // 修改
    getDetail () {
      this.$store.dispatch('getEmergencyPlanDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
      })
    },
    // 新增车型
    onClickAddCar () {
      this.carList.push({
        classid: '',
        classname: '',
      })
    },
    // 删除车
    onClickCarDelete (index) {
      this.carList.splice(index, 1)

    },
    // 新增工种
    onClickAddPerson () {
      this.personList.push({
        classid: '',
        classname: ''
      })
    },
    // 删除工种
    onClickPersonDelete (index) {
      this.personList.splice(index, 1)
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.resplanName) return this.$Message.info('请输入预案名称');
      if (!this.emergenttypeid) return this.$Message.info('请选择应急类型');
      if (!this.emergentlevelid) return this.$Message.info('请选择预案等级');
      if (!this.respondtime) return this.$Message.info('请输入响应时长');
      if (!this.dealtime) return this.$Message.info('请输入结束时间');
      if (!this.remark) return this.$Message.info('请输入备注');
      if (!this.carList[0].classid) return this.$Message.info('请选择车型');
      if (!this.personList[0].classid) return this.$Message.info('请选择工种');
      let params = {
        resplanName: this.resplanName,  // 预案名称
        emergenttypeid: this.emergenttypeid, // 应急类型
        emergentlevelid: this.emergentlevelid, //  预案等级
        respondtime: this.respondtime, // 响应时长分钟
        dealtime: this.dealtime,  // 处理时长
        remark: this.remark, // 备注        
        status: 1, // 状态        
      }
      if (this.carList[0].classid != '') params.carList = this.carList
      if (this.personList[0].classid != '') params.personList = this.personList
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('cearteOrEditEmergencyPlan', params).then(res => {
        this.loading = false
        this.$store.dispatch('updateIntendAllList', true)
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      })
    },
    // 车辆类型选择
    onChangeCarType (data) {
      if (data) {
        this.carList.forEach(item => {
          if (item.classid == data.value) {
            item.classname = data.label
          }
        });
      }
    },
    // 人员类型选择
    onChangePersonType (data) {
      if (data) {
        this.personList.forEach(item => {
          if (item.classid == data.value) {
            item.classname = data.label
          }
        });
      }
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.resplanName = ''
      this.emergenttypeid = ''
      this.emergentlevelid = ''
      this.respondtime = ''
      this.dealtime = ''
      this.remark = ''
      this.carList = [
        {
          classid: '',
          classname: '',
        }
      ]
      this.personList = [
        {
          classid: '',
          classname: ''
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-cont {
  .form-ul {
    .form-ul-li {
      .delete {
        cursor: pointer;
        line-height: 32px;
        color: #25bb96;
      }
    }
  }
}

.portion {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  p {
    font-weight: 700;
    font-size: 16px;
    padding-left: 10px;
    border-left: 2px solid #25bb96;
    margin-right: 10px;
  }
}

.li-musts {
  color: #ff0000;
  font-size: 16px;
  margin-right: 2px;
}
</style>