<template>
  <Modal :value="visible"
         :title="status == 3 ? '处理' : status == 4 ? '结束' : ''"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: String,
    visible: Boolean,
    dataId: String,
    status: String
  },
  data () {
    return {
      remark: '',
    }
  },
  methods: {
    // 选择日期
    onChangeDate (date) {
      this.date = date
    },
    // 确定按钮
    onClickConfirm () {
      let params = {
        ids: this.dataId,
        status: this.status,
        remark: this.remark
      }
      this.loading = true
      this.$http.emergentPlanningDispose(params).then(res => {
        this.$Message.info(res.message)
        if (res.code === 200) {
          this.loading = false
          this.$emit('onClickConfirm')
          this.$emit('onChange', false)
        }
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.$emit('update:status', '')
        this.resetData()
      }
    },
    resetData () {
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.form-minheight {
  height: 300px;
}
.form-ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    width: 100%;
  }
  .half {
    width: 48%;
    margin-right: 2%;
  }
}
</style>