import { render, staticRenderFns } from "./EmergencyDispatchIntendModal.vue?vue&type=template&id=2a6b24bd&scoped=true"
import script from "./EmergencyDispatchIntendModal.vue?vue&type=script&lang=js"
export * from "./EmergencyDispatchIntendModal.vue?vue&type=script&lang=js"
import style0 from "./EmergencyDispatchIntendModal.vue?vue&type=style&index=0&id=2a6b24bd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a6b24bd",
  null
  
)

export default component.exports