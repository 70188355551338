<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>应急等级：</p>
          <Input v-model="level"
                 maxlength="30"
                 v-stringLimit
                 placeholder="应急等级"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>颜色：</p>
          <ul class="li-color">
            <li v-for="(item,index) in colorArr"
                :key="item.id"
                @click.stop="onClickColor(index)"
                :class="{current:colorIndex == index}"
                :style="{background:item.color}">
            </li>
          </ul>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '应急等级'
    },
    visible: Boolean,
    dataId: String
  },
  data () {
    return {
      level: '',   // 等级名称
      color: 'red',
      remark: '',
      colorIndex: 0,
      colorArr: [
        {
          id: 1,
          color: '#ee6666'
        },
        {
          id: 2,
          color: '#fac858'
        },
        {
          id: 3,
          color: '#73c0de'
        },
        {
          id: 4,
          color: '#fc8452'
        },
        {
          id: 5,
          color: '#ea7ccc'
        },
        {
          id: 6,
          color: '#91cc75'
        },
        {
          id: 7,
          color: '#9a60b4'
        },
      ],
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getEmergencyLevelDetail', this.dataId).then(res => {
        console.log(res);
        for (let i in res) {
          this[i] = res[i]
        }
        this.colorArr.forEach(item => {
          if (item.color == this.color) return this.colorIndex = item.id - 1
        });
      })
    },
    // 选择颜色
    onClickColor (index) {
      this.colorIndex = index
      this.color = this.colorArr[index].color
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.level) return this.$Message.info('请输入应急等级');
      let params = {
        level: this.level,
        color: this.color,
        remark: this.remark
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('cearteOrEditEmergencyLevel', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.$store.dispatch('updateEmergencyLevelSelectList', true)
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.level = ''
      this.color = ''
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.li-color {
  display: flex;
  align-items: center;
  li {
    width: 25px;
    height: 25px;
    margin-right: 8px;
    border-radius: 2px;
    cursor: pointer;
    border: 4px solid rgb(255, 255, 255);
  }
  .current {
    border: 4px solid rgb(204, 228, 255);
  }
}
</style>