// 应急预案
<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex===0">
              <Select v-model="executingState"
                      placeholder="执行状态"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in executingStateArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="emergencyLevel"
                      placeholder="应急等级"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in emergencyLevelArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="planName"
                      placeholder="计划名称"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in planNameArr"
                        :value="item.name"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <DatePicker type="daterange"
                          :value="date"
                          :start-date="new Date()"
                          placeholder="请选择时间范围"
                          @on-change="onChangeDate"
                          style="width:260px"></DatePicker>
            </template>
            <template v-if="currentTabIndex===1">
              <Select v-model="emergencyTypeSelect"
                      placeholder="应急类型"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in emergencyTypeSelectArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="emergencyLevel"
                      placeholder="应急等级"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in emergencyLevelArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Input v-model="planName"
                     placeholder="预案名称"
                     maxlength="30"
                     v-stringLimit
                     clearable
                     class="m-r-10"
                     style="width:160px" />
            </template>
          </div>
          <div class="flex">
            <template v-if="currentTabIndex===0">
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/emergencyDispatch/query'])"
                      :ghost="buttonIndex == 0 ? false : true"
                      @click.stop="onClickCurrentMonth">本月</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/emergencyDispatch/query'])"
                      :ghost="buttonIndex == 1 ? false : true"
                      @click.stop="onClickLastMonth">上月</Button>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/emergencyDispatch/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/emergencyDispatch/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template v-if="currentTabIndex===0">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/emergencyDispatch/add'])"
                    @click.stop="emergencyDispatchPlanVisible=true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              下发计划
            </Button>
            <!-- <Button class="m-r-10"
                    v-hasPermi="['/admin/emergencyDispatch/export']">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button> -->
            <Dropdown @on-click="onClickBatch"
                      v-if="checkPermi(['/admin/emergencyDispatch/delete','/admin/emergencyDispatch/edit'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="end"
                              v-if="checkPermi(['/admin/emergencyDispatch/edit'])">批量结束</DropdownItem>
                <DropdownItem name="delete"
                              v-if="checkPermi(['/admin/emergencyDispatch/delete'])">批量删除</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </template>
        <template v-if="currentTabIndex===1">
          <div>
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/emergencyDispatch/add'])"
                    @click.stop="emergencyDispatchIntendVisible=true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加预案
            </Button>
            <Dropdown @on-click="onClickBatch"
                      v-if="checkPermi(['/admin/emergencyDispatch/delete','/admin/emergencyDispatch/edit'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="recovery"
                              v-if="checkPermi(['/admin/emergencyDispatch/edit'])">批量恢复</DropdownItem>
                <DropdownItem name="disable"
                              v-if="checkPermi(['/admin/emergencyDispatch/edit'])">批量禁用</DropdownItem>
                <DropdownItem name="delete"
                              v-if="checkPermi(['/admin/emergencyDispatch/delete'])">批量删除</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <Button type="text"
                  @click.stop="emergencyDispatchDictVisible=true">设置应急预案字典>></Button>
        </template>
      </div>
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               :row-class-name="rowClassName"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="operation">
            <template v-if="currentTabIndex===0">
              <!-- 0 1 2进行中  3已处理 4已完成 -->
              <Button @click.stop="onClickEditPlanning(row)"
                      v-if="row.status == 0 || row.status == 1 || row.status == 2"
                      style="color:#25bb96;padding:0 5px"
                      type="text"
                      v-hasPermi="['/admin/emergencyDispatch/edit']">修改</Button>
              <Button @click.stop="onClickDisposePlanning(row,'3')"
                      v-if="row.status == 0 || row.status == 1 || row.status == 2"
                      style="color:#25bb96;padding:0 5px"
                      type="text"
                      v-hasPermi="['/admin/emergencyDispatch/edit']">处理</Button>
              <Button @click.stop="onClickDisposePlanning(row,'4')"
                      v-if="row.status == 0 || row.status == 1 || row.status == 2 || row.status == 3"
                      style="color:#25bb96;padding:0 5px"
                      type="text"
                      v-hasPermi="['/admin/emergencyDispatch/edit']">结束</Button>
              <Button @click.stop="onClickDeletePlanning(row)"
                      v-if="row.status == 0 || row.status == 1 || row.status == 2"
                      style="color:#25bb96;padding:0 5px"
                      type="text"
                      v-hasPermi="['/admin/emergencyDispatch/delete']">删除</Button>
              <Button @click.stop=""
                      v-if="row.status == 4"
                      :disabled='disabled'
                      style="color:#25bb96;padding:0 5px"
                      type="text">已完结</Button>
            </template>
            <template v-else>
              <Button @click.stop="onClickEditPlan(row)"
                      style="color:#25bb96;padding:0 5px"
                      type="text"
                      v-hasPermi="['/admin/emergencyDispatch/edit']">修改</Button>
              <Button @click.stop="onClickDeletePlan(row)"
                      style="color:#25bb96;padding:0 5px"
                      type="text"
                      v-hasPermi="['/admin/emergencyDispatch/delete']">删除</Button>
              <Button @click.stop="onClickPlanDisable(row)"
                      style="color:#25bb96;padding:0 5px"
                      type="text"
                      v-hasPermi="['/admin/emergencyDispatch/edit']">{{row.status==='启用'?'禁用':'恢复'}}</Button>
            </template>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>
    <!-- 下发计划 -->
    <EmergencyDispatchPlanModal v-model="emergencyDispatchPlanVisible"
                                :dataId.sync="emergencyDispatchPlanId"
                                :width="drawerWidth"
                                @onClickConfirm="getList"
                                @onChange='onChangClearRowStyle'></EmergencyDispatchPlanModal>

    <!-- 添加预案 -->
    <EmergencyDispatchIntendModal v-model="emergencyDispatchIntendVisible"
                                  :dataId.sync="emergencyDispatchIntendId"
                                  @onClickConfirm="getList"
                                  @onChange='onChangClearRowStyle'></EmergencyDispatchIntendModal>
    <!-- 预案字典 -->
    <EmergencyDispatchDictModal v-model="emergencyDispatchDictVisible"
                                @onChange='onChangClearRowStyle'></EmergencyDispatchDictModal>

    <!-- 计划列表 处理 结束 -->
    <EmergencyPlanDisposeModal v-model="emergencyPlanDisposeVisible"
                               :status="emergencyPlanDisposeStatus"
                               :dataId="emergencyPlanDisposeId"
                               @onClickConfirm="getList"
                               @onChange='onChangClearRowStyle'></EmergencyPlanDisposeModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import EmergencyDispatchPlanModal from '@/components/product/admin/modal/patrolMaintain/EmergencyDispatchPlanModal'
import EmergencyDispatchIntendModal from '@/components/product/admin/modal/patrolMaintain/EmergencyDispatchIntendModal'
import EmergencyDispatchDictModal from '@/components/product/admin/modal/patrolMaintain/EmergencyDispatchDictModal'
import EmergencyPlanDisposeModal from '@/components/product/admin/modal/patrolMaintain/EmergencyPlanDisposeModal'
import { getCurrentMonth, getLastMonth, getCurrentWeek, getNearlyThreeDay } from '@/utils/dateRangUtil'
import { checkPermi } from '@/utils/permission'
export default {
  name: 'EmergencyDispatch',
  components: {
    EmergencyDispatchPlanModal,
    EmergencyDispatchIntendModal,
    EmergencyDispatchDictModal,
    EmergencyPlanDisposeModal
  },
  data () {
    return {
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: '应急计划'
        },
        {
          name: '应急预案'
        },
      ],
      executingState: '',
      executingStateArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '进行中'
        },
        {
          id: 2,
          name: '已完成'
        },
      ],
      responseModel: '',
      responseModelArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '单次'
        },
        {
          id: 2,
          name: '周期'
        },
      ],
      emergencyLevel: '',
      emergencyTypeSelect: '',
      planName: '',
      planNameArr: [
        {
          id: '',
          name: '全部'
        }
      ],
      date: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      resplanName: '',
      emergentTypeId: '',
      emergentLevelId: '',
      planName: '',
      tableHeight: 0,
      tableLoading: false,
      columnsPlan: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '计划名称',
          key: 'planname',
          tooltip: true,
          width: 80,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '执行状态',
          key: 'statusname',
          tooltip: true,
          width: 70,
          align: 'center'
        },
        {
          title: '下发时间',
          key: 'createtime',
          tooltip: true,
          width: 140,
          sortable: 'custom',
          align: 'center'
        },
        {
          title: '责任人',
          key: 'dutypersonname',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '作业车辆',
          key: 'askcar',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业人员',
          key: 'askemp',
          tooltip: true,
          minWidth: 120,
          align: 'center',
        },
        {
          title: '预案名称',
          key: 'resplanname',
          tooltip: true,
          width: 120,
          align: 'center'
        },
        {
          title: '应急类型',
          key: 'resplanlevel',
          tooltip: true,
          width: 60,
          align: 'center'
        },
        {
          title: '作业位置',
          key: 'workplacedetail',
          tooltip: true,
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.gridId) {
              return h('span', row.gridname)
            }
            return h('span', row.workplacedetail)
          }
        },
        {
          title: '现场媒体文件',
          key: 'managePhone',
          tooltip: true,
          width: 90,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.jpgfileurl) {
              return h('el-image', {
                props: {
                  src: row.jpgfileurl,
                  'preview-src-list': [row.jpgfileurl]
                },
                style: {
                  width: '22px',
                  height: '22px',
                  cursor: 'pointer'
                }
              })
            }
            return h('span', '')
          }
        },
        {
          title: '现场描述',
          key: 'detail',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        {
          title: '处理图片',
          key: 'managePhone',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '处理描述',
          key: 'managePhone',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      columnsPlann: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '预案名称',
          key: 'resplanname',
          tooltip: true,
          width: 120,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == '启用') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '停用')
          }
        },
        {
          title: '应急等级',
          key: 'emergentlevelid',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '颜色',
          key: 'color',
          tooltip: true,
          width: 45,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let color = row.color
            return h('span', [
              h('i', {
                style: {
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '2px',
                  background: color
                }
              }),
              h('span')
            ]);
          }
        },
        {
          title: '应急类型',
          key: 'emergenttypeid',
          tooltip: true,
          width: 120,
          align: 'center'
        },
        {
          title: '响应时长',
          key: 'respondtime',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '处理时长',
          key: 'dealtime',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '作业车辆要求',
          key: 'askcar',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业人员要求',
          key: 'askemp',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 下发计划
      emergencyDispatchPlanVisible: false,
      emergencyDispatchPlanId: '',
      // 添加预案
      emergencyDispatchIntendVisible: false,
      emergencyDispatchIntendId: '',
      // 预案字典
      emergencyDispatchDictVisible: false,
      disabled: true,
      drawerWidth: 0,
      // 计划列表 处理 结束
      emergencyPlanDisposeVisible: false,
      emergencyPlanDisposeStatus: '',
      emergencyPlanDisposeId: ''
    };
  },
  computed: {
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsPlan
          break;
        case 1:
          columns = this.columnsPlann
          break;
        default:
          break;
      }
      return columns
    },
    emergencyLevelArr () {
      let arr = [{
        id: '',
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getEmergencyLevelSelectList)
    },
    emergencyTypeSelectArr () {
      let arr = [{
        id: '',
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getEmergencyTypeSelectList)
    }
  },
  watch: {
    currentTabIndex () {
      this.getList()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }

  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateEmergencyLevelSelectList',
      'updateEmergencyTypeSelect'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.getList()
      this.updateEmergencyLevelSelectList()
      this.updateEmergencyTypeSelect()
      this.getEmergencyPlanList()
    },
    onClickSet () {

    },
    onChangeDate (date) {
      this.date = date
      this.buttonIndex = null
    },
    // 批量操作下拉菜单
    onClickBatch (name) {
      if (this.selectArr.length == 0) return this.$Message.info('请选择数据')
      switch (name) {
        case 'delete':
          switch (this.currentTabIndex) {
            case 0:
              for (const item of this.selectDataArr) {
                if (item.status != 0 && item.status != 1 && item.status != 2 && item.status != 3) {
                  return this.$Message.info('请选择正确数据')
                }
              }
              this.onDeleteBatchPlanning(this.selectArr)
              break;
            case 1:
              for (const item of this.selectDataArr) {
                if (item.tlEmergentPlanList.length > 0) {
                  this.$Modal.warning({
                    title: '无法删除！',
                    content: '“' + item.resplanname + '”有绑定数据，无法删除！',
                  })
                  return
                }
              }
              this.onDeletePlan(this.selectArr)
              break;
            default:
              break;
          }
          break;
        case 'end':
          for (const item of this.selectDataArr) {
            if (item.status != 0 && item.status != 1 && item.status != 2 && item.status != 3) {
              return this.$Message.info('请选择正确数据')
            }
          }
          this.emergencyPlanDisposeVisible = true
          this.emergencyPlanDisposeId = this.selectArr.join()
          this.emergencyPlanDisposeStatus = '4'
          break;
        // 恢复
        case 'recovery':
          this.$Modal.confirm({
            title: '恢复',
            content: '确认恢复?',
            onOk: () => {
              let params = {
                ids: this.selectArr.join(),
                status: 1,
              }
              this.$store.dispatch('disableBatchEmergencyPlan', params).then(res => {
                this.getList()
                this.$store.dispatch('updateIntendAllList', true)
              }).catch(err => {
                console.log(err);
                this.$Message.info(err.message)
              })
            }
          })
          break;
        // 禁用
        case 'disable':
          this.$Modal.confirm({
            title: '禁用',
            content: '确认禁用?',
            onOk: () => {
              let params = {
                ids: this.selectArr.join(),
                status: 0,
              }
              this.$store.dispatch('disableBatchEmergencyPlan', params).then(res => {
                this.getList()
                this.$store.dispatch('updateIntendAllList', true)
              }).catch(err => {
                this.$Message.info(err.message)
              })
            }
          })
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getTlemergentplanGetList()
          break;
        case 1:
          this.getEmergencyPlanGetList()
          break;
        default:
          break;
      }
    },
    // 应急计划列表
    getTlemergentplanGetList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        status: this.executingState == 0 ? '' : this.executingState == 1 ? '0' : '1',
        planname: this.planName,
        starttime: this.date[0],
        endtime: this.date[1],
        levelId: this.emergencyLevel
      }
      if (this.executingState) params.status = this.executingState === 1 ? 0 : 1
      if (this.date.length > 0) {
        params.starttime = this.date[0]
        params.endtime = this.date[1]
      }
      if (this.planName) params.planname = this.planName === '全部' ? '' : this.planName
      this.tableLoading = true
      this.$http.getTlemergentplanGetList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.tlEmergentPlanList
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改应急计划
    onClickEditPlanning (row) {
      this.selectVisitedArr.push(row)
      this.emergencyDispatchPlanVisible = true
      this.emergencyDispatchPlanId = row.id
    },
    // 处理 / 结束应急计划
    onClickDisposePlanning (row, status) {
      this.selectVisitedArr.push(row)
      this.emergencyPlanDisposeVisible = true
      this.emergencyPlanDisposeId = row.id
      this.emergencyPlanDisposeStatus = status
    },
    // 删除应急计划
    onClickDeletePlanning (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteBatchPlanning([row.id])
    },
    // 批量删除应急计划
    onDeleteBatchPlanning (row) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: row.join()
          }
          this.$http.deleteEmergentPlanning(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 应急预案列表
    getEmergencyPlanGetList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        status: '',
        resplanname: this.planName, // 预案名称
        emergenttypeid: this.emergencyTypeSelect, // 应急类型
        emergentlevelid: this.emergencyLevel, // 预案等级
      }
      this.tableLoading = true
      this.$http.getEmergencyPlanGetList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          console.log(res);
          this.totalPage = res.result.total
          this.data = res.result.tlEmergentResPlanList
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改预案
    onClickEditPlan (row) {
      this.selectVisitedArr.push(row)
      this.emergencyDispatchIntendId = row.id
      this.emergencyDispatchIntendVisible = true
    },
    // 删除应急预案
    onClickDeletePlan (row) {
      this.selectVisitedArr.push(row)
      if (row.tlEmergentPlanList.length > 0) {
        this.$Modal.confirm({
          title: '无法删除！',
          content: '该预案有绑定数据，无法删除！',
          onOk: () => {
          this.onChangClearRowStyle()
        },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })

        this.selectVisitedArr.push(row)

      } else {
        this.onDeletePlan([row.id])
      }

    },
    onDeletePlan (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr.join()
          }
          this.$http.deleteEmergencyPlan(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.$store.dispatch('updateIntendAllList', true)
              this.getList()
              this.onChangClearRowStyle()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 禁用or启用应急预案
    onClickPlanDisable (row) {
      this.selectVisitedArr.push(row)
      if (row.status == '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0,
            }
            this.$store.dispatch('cearteOrEditEmergencyPlan', params).then(res => {
              this.getList()
              this.$store.dispatch('updateIntendAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1,
            }
            this.$store.dispatch('cearteOrEditEmergencyPlan', params).then(res => {
              this.getList()
              this.$store.dispatch('updateIntendAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth()
      this.onClickSearch()
      this.buttonIndex = 0
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      this.onClickSearch()
      this.buttonIndex = 1
    },
    // 重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.planName = ''
      this.date = []
      this.executingState = ''
      this.emergencyLevel = ''
      this.emergencyTypeSelect = ''
      this.buttonIndex = null
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    // 应急计划名称列表
    getEmergencyPlanList () {
      this.$http.getEmergencyPlanList({ status: '' }).then(res => {
        if (res.code === 200) {
          let data = res.result
          this.planNameArr = this.planNameArr.concat(data)
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .table {
      margin-top: 16px;
    }
  }
}
</style>