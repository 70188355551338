<template>
  <Modal :value="visible"
         :title="title"
         footer-hide
         @on-visible-change="onChangeVisible"
         width="1000"
         class="dict-modal"
         class-name="vertical-center-modal">
    <div class="dict">
      <ul class="dict-left">
        <li v-for="(item,index) in dictArr"
            :key="index"
            :class="{'current-dict' : dictIndex == index}"
            @click.stop="onClickDict(index)">{{item.name}}</li>
      </ul>
      <div class="dict-right">
        <Table ref="table"
               height="480"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading">
          <template slot-scope="{ row }"
                    slot="operation">
            <template v-if="dictIndex===0">
              <Button @click.stop="onClickEditEmergencyType(row)"
                      style="color:#25bb96;padding:0 5px"
                      type="text">修改</Button>
              <Button @click.stop="onClickEmergencyTypeDisable(row)"
                      style="color:#25bb96;padding:0 5px"
                      type="text">{{row.status==='启用'?'禁用':'恢复'}}</Button>
            </template>
            <template v-else>
              <Button @click.stop="onClickEditEmergencyLevel(row)"
                      style="color:#25bb96;padding:0 5px"
                      type="text">修改</Button>
              <Button @click.stop="onClickEmergencyLevelDisable(row)"
                      style="color:#25bb96;padding:0 5px"
                      type="text">{{row.status==='启用'?'禁用':'恢复'}}</Button>
              <Button @click.stop="onClickEmergencyLevelDelete(row)"
                      style="color:#25bb96;padding:0 5px"
                      type="text">删除</Button>
            </template>
          </template>
        </Table>
        <div class="dict-right-btn">
          <Button type="text"
                  v-if="dictIndex === 0"
                  @click.stop="emergencyDispatchTypeVisible=true">
            <Icon custom="i-icon icon-tianjiabiankuang"
                  size="16" />
            新增应急类型
          </Button>
          <Button type="text"
                  v-else
                  @click.stop="emergencyDispatchGradeVisible=true">
            <Icon custom="i-icon icon-tianjiabiankuang"
                  size="16" />
            新增应急等级
          </Button>
        </div>
      </div>
    </div>

    <!-- 新增 / 修改应急类型 -->
    <EmergencyDispatchTypeModal v-model="emergencyDispatchTypeVisible"
                                :dataId.sync="emergencyDispatchTypeId"
                                @onClickConfirm='getList'></EmergencyDispatchTypeModal>

    <!-- 新增 / 修改应急等级 -->
    <EmergencyDispatchGradeModal v-model="emergencyDispatchGradeVisible"
                                 :dataId.sync="emergencyDispatchGradeId"
                                 @onClickConfirm='getList'></EmergencyDispatchGradeModal>
  </Modal>
</template>

<script>
import EmergencyDispatchTypeModal from '@/components/product/admin/modal/patrolMaintain/EmergencyDispatchTypeModal'
import EmergencyDispatchGradeModal from '@/components/product/admin/modal/patrolMaintain/EmergencyDispatchGradeModal'
export default {
  components: {
    EmergencyDispatchTypeModal,
    EmergencyDispatchGradeModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '应急预案字典'
    },
    visible: Boolean,
  },
  data () {
    return {
      dictIndex: 0,
      pageSize: 20,
      pageNum: 1,
      columnsType: [
        {
          title: '事件类型',
          key: 'eventtype',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      columnsGrade: [
        {
          title: '应急类型',
          key: 'level',
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      data: [],
      tableLoading: false,
      dictArr: [
        {
          name: '应急类型'
        },
        {
          name: '应急等级'
        }
      ],
      // 应急类型
      emergencyDispatchTypeVisible: false,
      emergencyDispatchTypeId: '',
      // 应急等级
      emergencyDispatchGradeVisible: false,
      emergencyDispatchGradeId: ''
    }
  },
  watch: {
    dictIndex () {
      this.getList()
    }
  },
  computed: {
    getColumns () {
      let columns = []
      switch (this.dictIndex) {
        case 0:
          columns = this.columnsType
          break;
        case 1:
          columns = this.columnsGrade
          break;
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getList()
    },
    // 切换选项
    onClickDict (i) {
      this.dictIndex = i
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
      }
    },
    // 获取列表
    getList () {
      switch (this.dictIndex) {
        case 0:
          this.getEmergencyTypeList()
          break;
        case 1:
          this.getEmergencyLevelList()
          break;
        default:
          break;
      }
    },
    // 获取应急类型列表
    getEmergencyTypeList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        status: '',
        eventtype: ''
      }
      this.tableLoading = true
      this.$http.getEmergencyTypeList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.data = res.result.resultlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改应急类型
    onClickEditEmergencyType (row) {
      this.emergencyDispatchTypeId = row.id
      this.emergencyDispatchTypeVisible = true
    },
    // 禁用/恢复应急类型
    onClickEmergencyTypeDisable (row) {
      if (row.status == '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0,
            }
            this.$store.dispatch('cearteOrEditEmergencyType', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1,
            }
            this.$store.dispatch('cearteOrEditEmergencyType', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      }
    },
    // 获取应急等级列表
    getEmergencyLevelList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        status: '',
      }
      this.tableLoading = true
      this.$http.getEmergencyLevelList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.data = res.result.resultlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改应急等级
    onClickEditEmergencyLevel (row) {
      this.emergencyDispatchGradeId = row.id
      this.emergencyDispatchGradeVisible = true
    },
    // 禁用/恢复应急等级
    onClickEmergencyLevelDisable (row) {
      if (row.status == '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0,
            }
            this.$store.dispatch('cearteOrEditEmergencyLevel', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1,
            }
            this.$store.dispatch('cearteOrEditEmergencyLevel', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      }
    },
    // 删除应急等级
    onClickEmergencyLevelDelete (row) {
      console.log(row,'d222dd');
      if (row.tlEmergentResplanList.length > 0) {
        this.$Modal.confirm({
          title: '无法删除!',
          content: '该等级有绑定数据，无法删除!',
        })
      } else {
        this.$Modal.confirm({
          title: '删除',
          content: '确认删除?',
          onOk: () => {
            let params = {
              id: row.id
            }
            this.$http.deleteEmergencyLevel(params).then(res => {
              this.$Message.info(res.message)
              if (res.code === 200) {
                this.getList()
                this.$store.dispatch('updateEmergencyLevelSelectList', true)
              }
            })
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dict-modal {
  ::v-deep {
    .ivu-modal-header {
      background-color: rgb(245, 245, 245);
      border-radius: 6px;
    }
    .ivu-modal-body {
      padding: 0;
    }
  }
}
.dict {
  display: flex;
  .dict-left {
    width: 110px;
    background-color: rgb(245, 245, 245);
    border-bottom-left-radius: 6px;
    li {
      line-height: 39px;
      text-align: center;
      padding: 3px 2px;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .current-dict {
    color: #25bb96;
  }
  .dict-right {
    flex: 1;
    padding: 8px;
    .dict-right-btn {
      margin-top: 10px;
      i {
        margin-right: 5px;
      }
    }
  }
}
</style>