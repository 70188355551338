<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>应急类型：</p>
          <Input v-model="eventType"
                 maxlength="30"
                 placeholder="应急类型"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '应急类型'
    },
    visible: Boolean,
    dataId: String
  },
  data () {
    return {
      eventType: '',   // 计划名称
      remark: ''
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getEmergencyTypeDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
      })
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.eventType) return this.$Message.info('请输入应急类型');
      let params = {
        eventType: this.eventType,
        remark: this.remark
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('cearteOrEditEmergencyType', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.eventType = ''
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>